//* Provider's
import LanguageProvider from './providers/LanguageProvider'
import UIProvider from './providers/UIProvider'
import DataProvider from './providers/DataProvider'
import UserProvider from './providers/UserProvider'
import CartProvider from './providers/CartProvider'
import FavoriteProvider from './providers/FavoriteProvider'

//* ConsumerHOC's
import withLanguageContext from './consumerHOC/LanguageConsumer'
import withUIContext from './consumerHOC/UIConsumer'
import withDataContext from './consumerHOC/DataConsumer'
import withUserContext from './consumerHOC/UserConsumer'
import withCartContext from './consumerHOC/CartConsumer'
import withFavoriteContext from './consumerHOC/FavoriteConsumer'

export {
	LanguageProvider,
	withLanguageContext,
	UIProvider,
	withUIContext,
	DataProvider,
	withDataContext,
	UserProvider,
	withUserContext,
	CartProvider,
	withCartContext,
	FavoriteProvider,
	withFavoriteContext,
}
