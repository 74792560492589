'use client';

import { Component, createContext } from 'react';
import { ThemeProvider } from 'styled-components';

//* Styles
import themes from '@/styles/theme';
import HelperClass from '@/styles/helperClass';
import Variables from '@/styles/variables';
import Typography from '@/styles/typography';

const UIContext = createContext(null);

class UIProvider extends Component {
    //! States
    state = {
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {},
        preloader: true,
        highComponent: null,
        preloaderHide: false,
        popupIsOpen: false,
        popupComponent: null,
        popupProps: {},
        isBurgerMenuOpen: false,
    };

    //! Methods
    methods = {
        addHighComponent: this.addHighComponent.bind(this),
        removeHighComponent: this.removeHighComponent.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
        openPopup: this.openPopup.bind(this),
        closePopup: this.closePopup.bind(this),
        togglePopup: this.togglePopup.bind(this),
        toggleBurgerMenu: this.toggleBurgerMenu.bind(this),
        closeBurgerMenu: this.closeBurgerMenu.bind(this),
    };

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        });
    };

    toggleBurgerMenu() {
        this.setState({ isBurgerMenuOpen: !this.state.isBurgerMenuOpen });
    }

    closeBurgerMenu() {
        this.state.isBurgerMenuOpen && this.setState({ isBurgerMenuOpen: false });
    }

    //! Open Popup
    openPopup(popupComponent = null, popupProps = {}) {
        // if (typeof popupComponent === 'string' && this.popup.includes(popupComponent) && this.props.router.query?.c !== popupComponent) {
        // 	const { pathname, query } = routerToPathAndQuery(this.props.router, 'c', popupComponent)
        // 	this.props.router.push({ pathname, query }, undefined, { shallow: true })
        // }

        // this.disableDomScroll()

        this.setState({
            popupIsOpen: true,
            popupComponent,
            popupProps,
        });
    }

    //! Close Popup
    closePopup(scroll = false) {
        // const { pathname, query } = routerToPathAndQuery(this.props.router, 'c')
        // this.props.router.push({ pathname, query }, undefined, { scroll: false })

        this.enableDomScroll();

        this.setState({
            popupIsOpen: false,
            popupComponent: null,
        });
    }

    //! Toggle Popup
    togglePopup() {
        this.setState({
            popupIsOpen: !this.state.popupIsOpen,
        });
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        //? document.querySelector('html').classList.add('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        //? if any scroll is attempted, set this to the previous value
        window.onscroll = function (e) {
            e.preventDefault();
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        //? document.querySelector('html').classList.remove('hide-scroll');

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        window.onscroll = function () { };
        window.scrollTo(scrollLeft, scrollTop);
    }

    animateFavicon() {
        let favicon1Href = document.querySelector('.favicon1').getAttribute('href');
        let dir = false;

        setInterval(() => {
            switch (favicon1Href.charAt(favicon1Href.length - 5)) {
                case '1':
                    favicon1Href = favicon1Href.replace('1', '2');
                    dir = !dir;
                    break;
                case '2':
                    if (dir) {
                        favicon1Href = favicon1Href.replace('2', '3');
                    } else {
                        favicon1Href = favicon1Href.replace('2', '1');
                    }
                    break;
                case '3':
                    if (dir) {
                        favicon1Href = favicon1Href.replace('3', '4');
                    } else {
                        favicon1Href = favicon1Href.replace('3', '2');
                    }
                    break;
                default:
                    favicon1Href = favicon1Href.replace('4', '3');
                    dir = !dir;
            }

            if (document.querySelector('.favicon1')) {
                document.querySelector('.favicon1').setAttribute('href', favicon1Href);
            }
        }, 500);
    }

    //! Component Did Mount
    componentDidMount() {
        this.setState({
            ...this.state,
        });

        window.addEventListener('resize', this.screenResize);
        this.screenResize();
        this.animateFavicon();
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize);
        document.removeEventListener('keydown', this.escKeydown, false);
    }

    //! Add High Component
    addHighComponent(component = null) {
        this.setState({
            highComponent: component,
        });
    }

    updateHighComponentProps(props) {
        const Comp = this.state.component;
        Comp.props = props;

        this.setState({
            highComponent: Comp,
        });
    }

    //! Remove High Component
    removeHighComponent() {
        this.setState({
            highComponent: null,
        });
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                <ThemeProvider theme={themes}>
                    <Variables />

                    <Typography
                        popupIsOpen={this.state.popupIsOpen}
                        isBurgerMenuOpen={this.state.isBurgerMenuOpen}
                    />

                    <HelperClass />

                    {this.props.children}
                </ThemeProvider>
            </UIContext.Provider>
        );
    }
}

export default UIProvider;
export const UIConsumer = UIContext.Consumer;
