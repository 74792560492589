'use client'

import { createContext, useState, useEffect, useRef } from 'react'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { api, setCookie, removeCookie, checkUserRedirection, config, getCookie } from '@/helpers'
import { objectToFormData } from '@/helpers/_functions'

const UserContext = createContext(null)

const UserProvider = (props) => {
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()

    const [state, setState] = useState({
        isLoggedIn: false,
        addresses: [],
        prevRoute: '/',
        ...props.userProps,
    })

    const previousRef = useRef()

    useEffect(() => {
        previousRef.current && setState((prev) => ({ ...prev, prevRoute: previousRef.current.prevRoute }))

        checkUserRedirection({
            user: state.user,
            pathname,
            redirectAutomatically: true,
            router,
            accessToken: getCookie('accessToken'),
        })

        return () => {
            previousRef.current = { prevRoute: pathname }
        }
    }, [pathname])

    const login = async (data) => {
        return await api.post('login', data).then(({ accessToken, user, addresses }) => {
            setCookie('accessToken', accessToken)

            setState((prev) => ({
                ...prev,
                isLoggedIn: true,
                user,
                addresses
            }))

            router.push(searchParams.get('r') || state.prevRoute || config.routes.account.path)
        })
    }

    const register = async (data) => {
        return await api.post('register', data).then(({ accessToken, user }) => {
            setCookie('accessToken', accessToken)

            setState((prev) => ({
                ...prev,
                isLoggedIn: true,
                user,
            }))

            router.push(`${config.routes.home.path}`)
        })
    }

    const forgotPassword = (data) => {
        return api.post('forgot-password', data)
    }

    const resetPassword = (data) => {
        return api.post('reset-password', data)
    }

    const updateAccount = async (data) => {
        return await api.post('update-account', data).then(() => {
            setState((prev) => ({
                ...prev,
                user: {
                    ...state.user,
                    ...data,
                },
            }))
        })
    }

    const updateAvatar = async (data) => {
        return await api.post('update-avatar', objectToFormData(data), undefined, 'multipart/form-data')
    }

    const updatePassword = (data) => {
        return api.post('update-password', data)
    }

    const createAddress = async (data) => {
        return await api.post('addresses', data).then(res => {
            setState(prev => ({
                ...prev,
                addresses: res.data
            }))
        })
    }

    const updateAddress = async (id, data) => {
        return await api.put(`addresses/${id}`, data).then(res => {
            setState(prev => ({
                ...prev,
                addresses: res.data
            }))
        })
    }

    const deleteAddress = async (id) => {
        return await api.delete(`addresses/${id}`).then(res => {
            setState(prev => ({
                ...prev,
                addresses: res.data
            }))
        })
    }

    const logout = async () => {
        return await api.post('logout').then(() => {
            removeCookie('accessToken')

            setState({
                isLoggedIn: false,
                user: null,
                addresses: []
            })

            router.push('/')
        })
    }

    const createReview = async (values) => {
        return await api.post('create-review', values)
    }

    const methods = {
        login,
        forgotPassword,
        resetPassword,
        register,
        updateAccount,
        updatePassword,
        updateAvatar,
        createAddress,
        updateAddress,
        deleteAddress,
        logout,
        createReview
    }

    return <UserContext.Provider value={{ ...state, ...methods }}>{props.children}</UserContext.Provider>
}

export default UserProvider
export const UserConsumer = UserContext.Consumer
