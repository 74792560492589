import api from './_api'
import config from './_config'
import { removeCookie } from './_functions'

export async function getUser(state, pathname, accessToken) {
    const accountParams = getRouteObjFromConfig(pathname)
    let newState = state
    let user, redirect, redirectUrl

    if (!accessToken && accountParams) {
        redirect = true
    } else if (accessToken) {
        user = await getInfo(accessToken)
        newState = { ...newState, ...user }
    }

    if (!user && accountParams) {
        redirect = true
        newState.isLoggedIn = false
        newState.user = {}
    } else if (user) {
        newState.isLoggedIn = true
    }

    redirectUrl = checkUserRedirection({ user: newState.user, pathname, params: accountParams, accessToken })

    if (redirectUrl) {
        redirect = true
    }

    return { newState, redirect, redirectUrl }
}

const getInfo = async (accessToken) => {
    return await api.get({ url: 'account', accessToken: accessToken.value }).catch((err) => {
        removeCookie('accessToken')
    })
}

export function checkUserRedirection({ user, pathname, redirectAutomatically, router, accessToken }) {
    const routes = config.routes
    const accountParams = getRouteObjFromConfig(pathname)
    let redirectUrl;

    if ((!user && accountParams) || (user && !accessToken)) {
        redirectUrl = routes.login.path
    } else if (user && (pathname.includes(routes.login.path) || pathname.includes(routes.register.path) || pathname.includes(routes.resetPassword.path) || pathname.includes(routes.forgotPassword.path))) {
        redirectUrl = routes.account.path
    }

    if (process.browser && redirectUrl && redirectAutomatically) {
        router.push(redirectUrl)
    }

    return redirectUrl
}

const getRouteObjFromConfig = (pathname) => {
    const privateRoutes = Object.values(config.routes).filter((r) => r?.private === true)

    let path = pathname

    const removedLastPath = path.substr(0, path.lastIndexOf('/'))

    let route = privateRoutes.filter((r) => {
        const pathArr = r.path.split(':')
        return r.path === path || r.path.replace(/\/$/, '') === path.replace(/\/$/, '') || (pathArr[0].replace(/\/$/, '') === removedLastPath && pathArr[1] && r.autoload)
    })[0]

    return pathname === '/404/' ? false : route
}
