import { forwardRef } from 'react'
import { FavoriteConsumer } from '../providers/FavoriteProvider'
import { getContext } from '@/helpers'

export default function withFavoriteContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<FavoriteConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {}

					return (
						<ConnectedComponent
							ref={ref}
							{...props}
							{...contextProps}
						/>
					)
				}}
			</FavoriteConsumer>
		)
	})
}
