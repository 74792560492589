'use client'

import { createContext, useState } from 'react'

//* Helpers
import isString from 'lodash.isstring'
import template from 'lodash.template'

//* JSONs
import Languages from '@/translations'

const LanguageContext = createContext()

const LanguageProvider = (props) => {
	const [state, setState] = useState({
		selectedLang: props.selectedLang || process.env.NEXT_APP_DEFAULT_LANGUAGE || 'en',
		languages: { ...Languages },
	})

	const selectLanguage = (lang) => {
		setState({ selectedLang: lang }, () => {
			localStorage.setItem('lang', lang)
		})
	}

	const translate = (val, vars) => {
		const compiled = isString(val) && state.languages[state.selectedLang][val] && template(state.languages[state.selectedLang][val])

		return (compiled && compiled(vars)) || val || 'Please Set Translate Value'
	}

	const methods = {
		selectLanguage,
		translate,
	}

	return <LanguageContext.Provider value={{ ...state, ...methods }}>{props.children}</LanguageContext.Provider>
}

export default LanguageProvider
export const LanguageConsumer = LanguageContext.Consumer
