'use client';

import { createContext, useEffect, useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';

//* HOC's
import withUserContext from '../consumerHOC/UserConsumer';

//* Helpers
import { api, setCookie, getCookie, removeCookie, config } from '@/helpers';

const CartContext = createContext(null);
export const CartConsumer = CartContext.Consumer;

const CartProvider = (props) => {
    //! Router
    const router = useRouter();
    const pathname = usePathname();

    const [state, setState] = useState({
        itemsCount: 0,
        items: [],
        subtotal: 0,
        shippingCost: 0,
        shippingMethodId: 0,
        couponAmount: null,
        couponCode: null,
        discount: null,
        currency: '',
        total: 0,
        cartLoading: true,
        checkoutAddress: null,
    });

    const getCookieItems = () => {
        const cookieCart = getCookie('cart');
        return cookieCart ? JSON.parse(cookieCart) : [];
    };

    const setResourceDecorator = (data) => {
        setState((prev) => ({
            ...prev,
            itemsCount: data.items_qty,
            subtotal: data.subtotal,
            total: data.total,
            items: data.items,
            shippingCost: data.shipping_cost,
            shippingMethodId: data.shipping_method_id,
            cartLoading: false,
            discount: data.discount,
            couponAmount: data.couponAmount,
            couponCode: data.couponCode,
        }));
    };

    const getCart = async (params = {}) => {
        if (!pathname.includes(config.routes.cart.path)) {
            setState((prev) => ({ ...prev, cartLoading: true }));
        }

        if (props.isLoggedIn) {
            return await api.get({ url: 'cart', params }).then(({ data }) => setResourceDecorator(data));
        } else {
            const cookieItems = getCookieItems();

            if (cookieItems) {
                return await api.post('guest-cart', { cart: cookieItems, ...params }).then(({ data }) => setResourceDecorator(data));
            }
        }
    };

    const guestToggleCartItem = ({ productId, qty }) => {
        const cookieItems = getCookieItems();
        const existingItem = cookieItems.find((item) => item.productId === productId);

        let items = [];

        if (!existingItem) {
            items = cookieItems.concat({ productId, qty });
        } else {
            items = cookieItems.map((item) =>
                item.productId === productId
                    ? Object.assign({}, item, {
                        qty,
                        productId,
                    })
                    : item
            );
        }

        setCookie('cart', JSON.stringify(items).trim());

        getCart();

        return Promise.resolve();
    };

    const customerToggleCartItem = async ({ productId, qty }) => {
        return await api.post('toggle-cart-item', { productId, qty }).then(({ data }) => setResourceDecorator(data));
    };

    const toggleCartItem = ({ productId, qty }) => {
        if (props.isLoggedIn) {
            return customerToggleCartItem({ productId, qty });
        } else {
            return guestToggleCartItem({ productId, qty });
        }
    };

    const deleteCartItem = async ({ productId }) => {
        if (props.isLoggedIn) {
            return await api.post('delete-cart-item', { productId }).then(({ data }) => setResourceDecorator(data));
        } else {
            const items = getCookieItems();

            const index = items.findIndex((item) => item.productId === productId);

            items.splice(index, 1);

            setCookie('cart', JSON.stringify(items).trim());

            getCart();
        }
    };

    const checkCoupon = async (code) => {
        return await api.post('check-coupon', code);
    };

    const setCheckoutAddress = (data) => {
        setState((prev) => ({
            ...prev,
            checkoutAddress: data,
        }));
    };

    const checkout = async (data) => {
        let checkoutData = {
            ...data,
            couponCode: state.couponCode,
            shippingMethodId: state.shippingMethodId,
        };

        if (props.isLoggedIn) {
            checkoutData['user_id'] = props.user.id;
        } else {
            checkoutData['items'] = state.items;
        }

        return await api.post('checkout', checkoutData).then((res) => {
            router.push(res.redirect_url);
        });
    };

    const clearCart = () => {
        setState({
            itemsCount: 0,
            subtotal: 0,
            total: 0,
            items: [],
            currency: '',
            shippingCost: '',
            cartLoading: false,
        });

        removeCookie('cart');
    };

    const methods = {
        getCart,
        toggleCartItem,
        deleteCartItem,
        checkCoupon,
        setCheckoutAddress,
        checkout,
        clearCart,
    };

    useEffect(() => {
        getCart();
    }, []);

    useEffect(() => {
        getCart();
    }, [props.isLoggedIn]);

    return <CartContext.Provider value={{ ...state, ...methods }}>{props.children}</CartContext.Provider>;
};

export default withUserContext(CartProvider, ['isLoggedIn', 'user']);
