export default {
	colors: {
		lightGreen: '#B0E3D9',
		green: '#17ABA6',
		white: '#ffffff',
		darkGrey: '#777777',
		grey: '#B5B5B5',
		black: '#252525',
		purple: '#402661',
		coral: '#F77566',
		pink: '#FFD1CF',
		lightWhite: '#FAFAFA',
	},

	mediaQuery: {
		DesktopSizeL: '1920px',
		DesktopSizeS: '1440px',
		DesktopSizeXS: '1280px',
		TabletSize: '1024px',
		TabletSizeS: '768px',

		DesktopSizeLMin: '1919px',
		DesktopSizeSMin: '1439px',
		DesktopSizeXSMin: '1279px',
		TabletSizeMin: '1023px',
		TabletSizeSMin: '767px',
	},
};
