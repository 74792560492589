import { createGlobalStyle, css } from 'styled-components';
import localFont from 'next/font/local';
import theme from './theme';

//! Fonts
const futuraPt = localFont({
	src: [
		{
			path: 'fonts/futura-light.woff2',
			weight: '300',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/futura-regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/futura-medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
		},
		{
			path: 'fonts/futura-demi.woff2',
			weight: '600',
			display: 'swap',
			style: 'normal',
		},
	],
});

function spaceGenerator() {
	let str = '';

	for (let i = 1; i <= 250; i++) {
		str += `--sp${i}x: calc(var(--spSize) * ${i});`;
	}

	return str;
}

function colorVariablesGenerator() {
	const colorsArr = Object.keys(theme.colors);

	let colorVariable = '';

	for (let i = 0; i < colorsArr.length; i++) {
		colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`;
	}

	return colorVariable;
}

const Variables = createGlobalStyle`${css`
	:root {
		//! Fonts
		--futura: ${futuraPt.style.fontFamily};

		//! Spacing
		--spSize: 8px;
		--sp0-5x: calc(var(--spSize) * 0.5);
		--sp1-5x: calc(var(--spSize) * 1.5);
		--sp2-5x: calc(var(--spSize) * 2.5);
		--sp3-5x: calc(var(--spSize) * 3.5);
		${spaceGenerator()};

		//! Colors
		${colorVariablesGenerator()};

		//! Line height
		--lineHeight: 1.2;

		//! Transition time
		--trTime: 0.3s;

		//! Grid Column Distance
		--colPadding: calc(var(--gutter) / 2);
	}

	/* //! 1920 */
	@media only screen and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		:root {
			--h1L: 54px;
			--h1: 48px;
			--h2L: 28px;
			--h2: 24px;
			--h3: 20px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp20x);
			--contPaddingLR: var(--sp30x);
			--gutter: var(--sp3x);
			--headerFixHeight: 112px;
		}
	}

	/* //! 1440 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		:root {
			--h1L: 48px;
			--h1: 38px;
			--h2L: 24px;
			--h2: 20px;
			--h3: 18px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp18x);
			--contPaddingLR: var(--sp10x);
			--gutter: var(--sp3x);
			--headerFixHeight: 96px;
		}
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		:root {
			--h1L: 46px;
			--h1: 36px;
			--h2L: 24px;
			--h2: 20px;
			--h3: 18px;
			--h4: 18px;
			--h5: 16px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp15x);
			--contPaddingLR: var(--sp10x);
			--gutter: var(--sp3x);
			--headerFixHeight: 96px;
		}
	}

	/* //! 1024 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		:root {
			--h1L: 42px;
			--h1: 32px;
			--h2L: 22px;
			--h2: 18px;
			--h3: 16px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp13x);
			--contPaddingLR: var(--sp8x);
			--gutter: var(--sp3x);
			--headerFixHeight: 96px;
		}
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		:root {
			--h1L: 42px;
			--h1: 32px;
			--h2L: 22px;
			--h2: 18px;
			--h3: 16px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp13x);
			--contPaddingLR: var(--sp6x);
			--gutter: var(--sp3x);
			--headerFixHeight: 96px;
		}
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		:root {
			--h1L: 32px;
			--h1: 30px;
			--h2L: 20px;
			--h2: 16px;
			--h3: 14px;
			--h4: 16px;
			--h5: 14px;
			--h6: 14px;
			--p: 12px;

			//! Container variables
			--sectionDistance: var(--sp10x);
			--contPaddingLR: var(--sp3x);
			--gutter: var(--sp2x);
			--headerFixHeight: 56px;
		}
	}
`}`;

export default Variables;
