'use client';

import { useState, useMemo, createContext } from 'react';

//* Context
import withLanguageContext from '../consumerHOC/LanguageConsumer';

//* Helpers
import { api } from '@/helpers';

const DataContext = createContext(null);
export const DataConsumer = DataContext.Consumer;

const DataProvider = (props) => {
	const [state, setState] = useState({
		...props.newData,
		prevRoute: null,
	});

	const enableLoading = () => {
		this.setState((prev) => ({ ...prev, loading: true }));
	};

	const disableLoading = () => {
		setState((prev) => ({ ...prev, loading: false }));
	};

	const globalData = useMemo(() => state.global?.[props.selectedLang], [props.selectedLang, state]);

	const subscribe = (data) => {
		return api.post('subscribe', data);
	};

	const methods = {
		enableLoading,
		disableLoading,
		subscribe,
	};

	return <DataContext.Provider value={{ ...state, ...methods, globalData }}>{props.children}</DataContext.Provider>;
};

export default withLanguageContext(DataProvider, ['selectedLang']);
