export default {
    routes: {
        home: {
            path: '/',
            slug: 'home',
            name: 'home',
        },
        blog: {
            path: '/blog',
            slug: 'blog',
            name: 'blog',
            header: true,
            desktop: 5,
            mobile: 5,
        },
        pantry: {
            path: '/product-category/pantry',
            slug: 'pantry',
            name: 'pantry',
            store: true,
            header: true,
            desktop: 1,
            mobile: 3,
            children: [],
        },
        wholeFoods: {
            path: '/product-category/whole-foods',
            slug: 'whole-foods',
            name: 'wholeFoods',
            store: true,
            header: true,
            desktop: 2,
            mobile: 4,
        },
        newProducts: {
            path: '/shop/?sort=latest',
            slug: 'new-in',
            name: 'newProducts',
            store: true,
            header: true,
            desktop: 3,
            mobile: 1,
            hasColor: true,
        },
        bestSellers: {
            path: '/shop/?sort=popularity',
            slug: 'best-sellers',
            name: 'bestSellers',
            store: true,
            header: true,
            desktop: 4,
            mobile: 2,
            hasColor: true,
        },
        aboutUs: {
            path: '/about-us',
            slug: 'about-us',
            name: 'aboutUs',
            forCustomer: true,
        },
        shippingDetails: {
            path: '/shipping-details',
            slug: 'Shipping-details',
            name: 'shippingDetails',
            forCustomer: true,
        },
        privacyAndReturns: {
            path: '/privacy-and-returns',
            slug: 'privacy-and-returns',
            name: 'privacyAndReturns',
            forCustomer: true,
        },
        account: {
            path: '/account',
            slug: 'account',
            name: 'account',
        },
        addresses: {
            path: '/account/addresses',
            slug: 'addresses',
            name: 'addresses',
        },
        accountDetails: {
            path: '/account/details',
            slug: 'details',
            name: 'details',
        },
        shop: {
            path: '/shop',
            slug: 'shop',
            name: 'shop',
        },
        wishlist: {
            path: '/wishlist',
            slug: 'wishlist',
            name: 'wishlist',
        },
        cart: {
            path: '/cart',
            slug: 'cart',
            name: 'cart',
        },
        checkout: {
            path: '/checkout',
            slug: 'checkout',
            name: 'checkout',
        },
        //! Auth
        login: {
            path: '/login',
            slug: 'login',
            name: 'login',
        },
        register: {
            path: '/register',
            slug: 'register',
            name: 'register',
        },
        forgotPassword: {
            path: '/forgot-password',
            slug: 'forgot-password',
            name: 'forgot-password',
        },
        resetPassword: {
            path: '/reset-password',
            slug: 'reset-password',
            name: 'reset-password',
        },
    },
};
