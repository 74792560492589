'use client'

import { createContext, useEffect, useState } from 'react'

//* HOC's
import withUserContext from '../consumerHOC/UserConsumer'

//* Helpers
import { api, setCookie, getCookie } from '@/helpers'

const FavoriteContext = createContext(null)
export const FavoriteConsumer = FavoriteContext.Consumer

const FavoriteProvider = (props) => {
    const [state, setState] = useState({
        favoritesCount: 0,
        favorites: [],
        favoriteLoading: true,
    })

    const getCookieItems = () => {
        const cookieFavorites = getCookie('favorites')
        return cookieFavorites ? JSON.parse(cookieFavorites) : []
    }

    const setResourceDecorator = (data) => {
        setState((prev) => ({
            ...prev,
            favoritesCount: data.length,
            favorites: data,
            favoriteLoading: false,
        }))
    }

    const getFavorites = async () => {
        setState((prev) => ({ ...prev, favoriteLoading: true }))

        if (props.isLoggedIn) {
            return await api.get({ url: 'favorites' }).then(({ data }) => setResourceDecorator(data))
        } else {
            const cookieItems = getCookieItems()
            if (cookieItems) {
                return await api.post('guest-favorites', { favorites: cookieItems }).then(({ data }) => setResourceDecorator(data))
            }
        }
    }

    const guestToggleFavorite = ({ productId }) => {
        const cookieItems = getCookieItems()
        const existingItem = cookieItems.find((item) => item === productId)

        let items = cookieItems;

        if (!existingItem) {
            items = cookieItems.concat(productId)
        } else {
            const index = items.findIndex((item) => item === productId)
            items.splice(index, 1)
        }

        setCookie('favorites', JSON.stringify(items).trim())

        getFavorites()

        return Promise.resolve()
    }

    const customerToggleFavorite = async ({ productId }) => {
        return await api.post('toggle-favorite', { productId }).then(({ data }) => setResourceDecorator(data))
    }

    const toggleFavorite = ({ productId }) => {
        if (props.isLoggedIn) {
            return customerToggleFavorite({ productId })
        } else {
            return guestToggleFavorite({ productId })
        }
    }

    const checkActiveFavorite = ({ productId }) => {
        return state.favorites.some((favorite) => favorite.id === productId)
    }

    const methods = {
        getFavorites,
        toggleFavorite,
        checkActiveFavorite,
    }

    useEffect(() => {
        getFavorites()
    }, [])

    useEffect(() => {
        getFavorites()
    }, [props.isLoggedIn])

    return <FavoriteContext.Provider value={{ ...state, ...methods }}>{props.children}</FavoriteContext.Provider>
}

export default withUserContext(FavoriteProvider, ['isLoggedIn'])
