import { createGlobalStyle, css } from 'styled-components';

function columnGenerator(suffix) {
	let style = '';

	for (let i = 1; i <= 8; i++) {
		style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`;

		style += `{ width: ${(100 / 8) * i}% }`;
	}

	return style;
}

const HelperClass = createGlobalStyle`${css`
	//! Font
	.font-futura {
		&-light {
			font-family: var(--futura);
			font-weight: 300 !important;
		}

		&-regular {
			font-family: var(--futura);
			font-weight: 400 !important;
		}

		&-medium {
			font-family: var(--futura);
			font-weight: 500 !important;
		}

		&-semibold {
			font-family: var(--futura);
			font-weight: 600 !important;
		}
	}

	//! Colors
	.black {
		color: var(--black);

		&-bg {
			background-color: var(--black);
		}
	}

	.white {
		color: var(--white);

		&-bg {
			background-color: var(--white);
		}
	}

	.grey {
		color: var(--grey);

		&-bg {
			background-color: var(--grey);
		}
	}

	.darkGrey {
		color: var(--darkGrey);

		&-bg {
			background-color: var(--darkGrey);
		}
	}

	.green {
		color: var(--green);

		&-bg {
			background-color: var(--green);
		}
	}

	.lightGreen {
		color: var(--lightGreen);

		&-bg {
			background-color: var(--lightGreen);
		}
	}

	.purple {
		color: var(--purple);

		&-bg {
			background-color: var(--purple);
		}
	}

	.coral {
		color: var(--coral);

		&-bg {
			background-color: var(--coral);
		}
	}

	.pink {
		color: var(--pink);

		&-bg {
			background-color: var(--pink);
		}
	}

	.lightWhite {
		color: var(--lightWhite);

		&-bg {
			background-color: var(--lightWhite);
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		line-height: var(--lineHeight);
	}

	//! Font sizes
	h1,
	.h1 {
		font-size: var(--h1);

		&-L {
			font-size: var(--h1L);
		}
	}

	h2,
	.h2 {
		font-size: var(--h2);

		&-L {
			font-size: var(--h2L);
		}
	}

	h3,
	.h3 {
		font-size: var(--h3);
	}

	h4,
	.h4 {
		font-size: var(--h4);
	}

	h5,
	.h5 {
		font-size: var(--h5);
	}

	h6,
	.h6 {
		font-size: var(--h6);
	}

	p,
	.p {
		font-size: var(--p);
	}

	//! Text
	.uppercase {
		text-transform: uppercase;
	}

	.underline {
		text-decoration: underline;
	}

	.text-center {
		text-align: center;
	}

	.cursor-pointer {
		cursor: pointer;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	//! Grid
	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--colPadding) * -1);

		> * {
			width: 100%;
			padding: 0 var(--colPadding);
		}

		/* //! 1920 */
		${columnGenerator()}

		/* //! 1440 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
			${columnGenerator('ds')};
		}

		/* //! 1280 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
			${columnGenerator('dxs')};
		}

		/* //! 1024 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
			${columnGenerator('t')};
		}

		/* //! 768 */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
			${columnGenerator('ts')};
		}

		/* //! Mobile */
		@media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
			${columnGenerator('m')};
		}
	}
`}`;

export default HelperClass;
