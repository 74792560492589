import { forwardRef } from 'react'
import { CartConsumer } from '../providers/CartProvider'
import { getContext } from '@/helpers'

export default function withCartContext(ConnectedComponent, properties = []) {
	return forwardRef((props, ref) => {
		return (
			<CartConsumer>
				{(context) => {
					let contextProps = properties.length ? getContext(context, properties) : {}
					return (
						<ConnectedComponent
							{...contextProps}
							{...props}
							ref={ref}
						/>
					)
				}}
			</CartConsumer>
		)
	})
}
